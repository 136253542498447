// COLORS
$orange: #FF7041;
$offWhite: #fbf8f3;
$black: #1D220D;
$green: #0D7D4E;

// FONTS
$poppins: 'Poppins', sans-serif;
$courgette: 'Courgette', cursive;
$raleway: 'Raleway', sans-serif;

// FONT SIZES
$desktopSmallText: 18px;
$tabletSmallText: 16px;
$mobileSmallText: 14px;
$desktopHeading: 32px;
$tabletHeading: 26px;
$mobileHeading: 20px;