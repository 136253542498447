@import '../partials/variables';
@import '../partials/mixins';

@media (min-width: 600px) and (max-width: 1024px) {
    .main-section{
        top: -75px;
    }

    .home-image {
        right: 60px;

        img {
            width: 50vw;
            max-width: 500px;
            min-width: 400px;
        }
 
        .blurred-color-bg-1 {
            width: 500px;
            height: 200px;
            left: -50px;
        }

        .blurred-color-bg-2 {
            width: 300px;
            height: 75px;
            bottom: 15px;
            left: 100px;
        }
    }

    .home-content {
        padding-left: 3rem;
        min-width: 475px;

        h1 {
            line-height: 52.5px;
            font-size: 42px;
        }

        p {
            line-height: 30px;
            font-size: $tabletSmallText;
            margin-top: .75rem;
        }

        .home-buttons {
            margin-top: 2rem;

            a {
                font-size: $tabletSmallText;
                padding: .6rem 1.5rem;
            }

            .calories-btn {
                margin-right: .75rem;
            }

            .recipes-btn {
                padding: .475rem 1.75rem;
                svg {
                    height: $tabletSmallText;
                }
            }
        }
    }
}