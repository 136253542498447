@import '../partials/variables';

@media (max-width: 768px) {
    .meal-planning-samples {
        width: 340px;
        padding: 2.5rem 1rem;

        article {
            margin-bottom: 2.5rem;
        }

        h2 {
            text-align: center;
            font-size: $mobileHeading;
            margin-bottom: 1.25rem;
        }

        table {
            font-size: 10px;

            thead,
            tbody {
                tr {
                    td:nth-child(1) {
                        width: 80px;
                    }

                    td:nth-child(2) {
                        width: 260px;
                    }

                    td {
                        padding: .25rem .5rem;
                    }
                }
            }
        }
    }
}