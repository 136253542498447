@import '../partials/variables';
@import '../partials/mixins';

@media (max-width: 768px) {
    .search-container {
        height: 250px;

        h1 {
            width: 22ch;
            font-size: 24px;
        }

        .search {
            width: 90%;

            input,
            .search-button {
                padding: .6rem 1.25rem;
                font-size: $mobileSmallText;
            }
        }
    }

    .loader{
        bottom: 1rem;

        .circle{
            width: 8px;
            height: 8px;
            margin: 0 .35rem;
        }
    }
}