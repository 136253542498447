@import './styles/partials/variables';
@import './styles/partials/mixins';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 1px;
}

body{
  overflow-x: hidden;
}

.App{
  position: relative;
  width: 100vw;
  min-height: 100vh;
  background-color: $offWhite;
  @include flex(column, center, flex-start);
}

.home, .recipes, .favorite-recipes, .calories{
  max-width: 100vw;
  width: 100%;
  @include flex(column, center, flex-start);
}

.recipes, .calories{
    position: relative;
}

.calories-sections{
  @include flex(column, center);
}