@import '../partials/variables';
@import '../partials/mixins';

@media (max-width: 599px) {
    .main-section {
        min-height: 650px;
        top: -55px;
        @include flex(column-reverse, center, space-evenly);
    }

    .home-image {
        right: unset;

        img {
            width: 75vw;
            min-width: 0px;
            max-width: 350px;
        }

        .blurred-color-bg-1,
        .blurred-color-bg-2 {
            filter: blur(50px);
        }

        .blurred-color-bg-1 {
            width: 300px;
            height: 150px;
            left: -65px;
        }

        .blurred-color-bg-2 {
            width: 280px;
            height: 80px;
            bottom: 0px;
        }
    }

    .home-content {
        width: 316px;
        padding-left: 0;
        min-width: 0px;

        h1 {
            line-height: 45px;
            font-size: 38px;
        }

        p {
            line-height: 25px;
            font-size: $mobileSmallText;
            margin-top: .75rem;
            width: 36ch;
        }

        .home-buttons {
            margin-top: 1.75rem;

            a {
                font-size: $mobileSmallText;
                padding: .5rem 1.2rem;
            }

            .calories-btn {
                margin-right: .5rem;
            }

            .recipes-btn {
                padding: .375rem 1.2rem;

                svg {
                    height: $mobileSmallText;
                    padding-right: .35rem;
                }
                span {
                    top: -.25px;
                }
            }
        }
    }
}