@import '../partials/variables';
@import '../partials/mixins';

.healthy-foods {
    position: relative;
    width: 100%;
    max-width: 1350px;
    padding: 4rem 2rem;

    h2 {
        text-align: center;
        color: $black;
        font-size: $desktopHeading;
        font-family: $raleway;
        margin-bottom: 3rem;
        font-weight: 800;
        letter-spacing: 1.5px;
        @include centerElements;
    }

    .food-items {
        @include flex(row, center, space-evenly);
        flex-wrap: wrap;

        .food-item {
            position: relative;
            cursor: pointer;
            @include centerElements;
            @include dropDownShadow;
            width: 195px;
            height: 70px;
            margin: 1rem;
            border-radius: 15px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            transition: all .15s ease;

            p {
                color: #fff;
                font-family: $poppins;
                font-size: 20px;
                font-weight: 600;
                z-index: 2;
                text-shadow: -3px 3px 10px rgba(38, 38, 38, 0.5);
            }

            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 57, 33, 0.6);
                z-index: 1;
                border-radius: 15px;
            }
        }

        .food-item:hover {
            transform: scale(1.02);
            @include dropDownShadowHover;
        }
    }

    .loader {
        width: 100%;
        position: absolute;
        top: 8rem;
        height: 10px;
        left: 0;
        z-index: 3;
        @include flex(row, center, center);


        .circle {
            width: 10px;
            height: 10px;
            margin: 0 .5rem;
            border-radius: 30px;
            background-color: $black;
            animation-name: fade-in-out;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
        }

        .circle-2 {
            animation-delay: .2s;
        }

        .circle-3 {
            animation-delay: .4s;
        }
    }

    .scroll-ref{
        position: absolute;
        top: -110px;
    }
}