@import '../partials/variables';

@media (min-width: 769px) and (max-width: 1024px) {
    .meal-planning-samples {
        width: 550px;
        padding: 3rem 2rem;

        article {
            margin-bottom: 4rem;
        }

        h2 {
            font-size: $tabletHeading;
            margin-bottom: 1.5rem;
        }

        table {
            font-size: 14px;

            thead,
            tbody {
                tr {
                td:nth-child(1){
                    width: 150px;
                }
                td:nth-child(2){
                    width: 400px;
                }

                    td {
                        padding: .5rem 1rem;
                    }
                }
            }
        }
    }
}