@import '../partials/variables';
@import '../partials/mixins';

.calorie-calculator {
    position: relative;
    width: 640px;
    @include flex(column, center);
    padding: 3.5rem 2rem; 

    h2 {
        color: $black;
        font-size: $desktopHeading;
        font-family: $raleway;
        margin-bottom: 2rem;
        font-weight: 800;
        letter-spacing: 1.5px;
    }

    .about-calculator {
        width: 100%;
        color: $black;
        font-family: $poppins;
        font-size: $desktopSmallText;
        line-height: 35px;
        margin-bottom: 2.5rem;
    }
}

.calculator-container {
    width: 100%;
    @include dropDownShadow(0.12);
    padding: 3rem;
    border-radius: 30px;

    .field-group {
        width: 100%;
        @include flex(row);
        margin-bottom: 1rem;

        .input-group:nth-child(1) {
            margin-right: 1rem;
        }

        .input-group:nth-child(2) {
            margin-left: 1rem;
        }
    }

    .input-group {
        font-size: 16px;
        font-family: poppins;
        position: relative;
        width: 100%;
        @include flex(column);

        label {
            margin-bottom: .5rem;
            font-weight: 600;
        }

        input[type="number"],
        select {
            border: none;
            outline: none;
            background-color: transparent;
            border: 2px solid $orange;
            border-width: 0 0 2px 0;
            padding: .75rem 1.5rem;
            font-weight: 400;
            font-size: 16px;
            font-family: poppins;
        }

        .radio-group {
            width: 100%;
            height: 100%;
            @include flex(row, center);

            label {
                font-weight: 400;
                margin: 0 1rem 0 0;
                padding: 0 0 0 .75rem;
                color: #000;
            }

            input[type="radio"],
            label {
                cursor: pointer;
            }
        }

        span {
            position: absolute;
            right: 1rem;
            bottom: 1rem;
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input::placeholder{
            color: rgb(155, 155, 155);
        }
        select {
            padding: .75rem 1.5rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            option {
                padding: .75rem 1.5rem;
            }
        }

        select:after {
            padding-right: 1rem;
        }

        .select-btn {
            bottom: .5rem;
            transform: rotateZ(90deg);
            font-size: 22px;
            font-family: poppins;
            font-weight: 500;
            color: $orange;
        }
    }

    .calculator-buttons {
        margin: 2.5rem 0 0;
        width: 100%;
        @include flex(row, flex-end, flex-end);

        button {
            cursor: pointer;
            border: none;
            outline: none;
            background-color: transparent;
            padding: .75rem 0;
            margin-left: 1.5rem;
            font-family: $poppins;
            font-size: 16px;
        }

        button[type="submit"] {
            opacity: .9;
            padding: .75rem 1.75rem;
            background-color: $orange;
            border-radius: 30px;
            color: #fff;
            transition: all .15s;
            @include dropDownShadow;

            svg {
                margin-right: 1.25rem;
            }
        }

        button[type="submit"]:hover {
            opacity: 1;
        }
    }
}

@keyframes fade-in-out {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.loader {
    position: absolute;
    bottom: 1.25rem;
    z-index: 3;
    @include flex(row);

    .circle {
        width: 10px;
        height: 10px;
        margin: 0 .5rem;
        border-radius: 30px;
        background-color: $orange;
        animation-name: fade-in-out;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
    }

    .circle-2 {
        animation-delay: .2s;
    }

    .circle-3 {
        animation-delay: .4s;
    }
}