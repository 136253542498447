@import '../partials/variables';
@import '../partials/mixins';

@media (min-width: 769px) and (max-width: 1024px) {
    .search-container {
        height: 300px;

        h1 {
            font-size: 28px;
        }

        .search {
            width: 450px;

            input,
            .search-button {
                padding: .7rem 1.65rem;
                font-size: $tabletSmallText;
            }
        }
    }

    .loader {
        .circle {
            width: 8px;
            height: 8px;
            margin: 0 .4rem;
        }
    }
}