@import '../partials/variables';
@import '../partials/mixins';

.recipes-title {
    text-align: center;
    color: $black;
    font-size: $desktopHeading;
    font-family: $raleway;
    margin-bottom: 3rem;
    font-weight: 800;
    letter-spacing: 1.5px;
}

.recipes-container {
    width: 100%;
    max-width: 1350px;
    padding: 0 2rem 4rem;
    @include flex(row, center, space-evenly);
    flex-wrap: wrap;
}

.recipe {
    cursor: pointer;
    position: relative;
    min-width: 250px;
    width: 250px;
    height: 200px;
    margin: 1rem;
    border-radius: 20px;
    background-color: $black;
    @include dropDownShadow(0.3);
    transition: all .15s ease;
    @include flex(column);
}

.recipe:hover {
    transform: scale(1.02);
    @include dropDownShadow(0.45);
}

.recipe-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 20px 20px 0 0;
}

.recipe-title {
    @include centerElements;

    p {
        color: $offWhite;
        padding: .65rem;
        font-family: $poppins;
        font-size: $desktopSmallText;
        font-weight: 600;
    }
}